import React from 'react';

const About = () => (
  <div className="row">
    <div className="col-md-12">
      <h1>Про систему</h1>

      <p>
        Інформаційно-телекомунікаційна система «Електронний кампус» – прикладне
        програмне забезпечення, яке є елементом інформаційно-телекомунікаційного
        середовища університету та використовується для інформаційної підтримки
        повсякденної діяльності студентів, викладачів, співробітників
        університету, а так само для інформаційної підтримки всіх видів
        інноваційної діяльності в університеті (далі ІТС ЕК).
      </p>

      <p>
        ІТС ЕК об'єднує внутрішні інформаційні ресурси (навчальні, методичні та
        інші), надає централізований доступ до них на основі єдиних системних і
        технологічних рішень та забезпечує їх використання для ефективного
        управління та планування науково-освітнім процесом.
      </p>
      <h3>Основні функції ІТС ЕК</h3>

      <p>ІТС ЕК забезпечує виконання таких основних функцій:</p>
      <ul>
        <li>
          <p>
            багатобічна комунікація між студентами, викладачами, науковим
            співтовариством;
          </p>
        </li>
        <li>
          <p>
            формування єдиного інформаційного ресурсу, що відображає стан
            науково-освітнього процесу університету;
          </p>
        </li>
        <li>
          <p>
            забезпечення своєчасного та оперативного розміщення: повної,
            об'єктивної, достовірної та несуперечливої інформації про освітній
            процес університету;
          </p>
        </li>
        <li>
          <p>
            розповсюдження інформації про майбутні події і заходи та інші
            довідкові відомості;
          </p>
        </li>
        <li>
          <p>
            забезпечення централізованого і зручного доступу до відомостей про
            діяльність ректорату і підрозділів університету;
          </p>
        </li>
        <li>
          <p>
            забезпечення навігації по всьому інформаційному наповненню ІТС ЕК;
          </p>
        </li>
        <li>
          <p>
            організація взаємодії та інформаційного обміну між ІТС ЕК та іншими
            інформаційними ресурсами і системами університету.
          </p>
        </li>
      </ul>

      <p>
        В системі ІТС ЕК розроблені віртуальні кабінети за профілями
        користувачів: студент, викладач-науковець, методист кафедри.
      </p>
    </div>
  </div>
);

export default About;
