import React from 'react';
// import { Link } from 'react-router-dom';

const ZKM = () => (
  <div className="row">
    <div className="col-md-12">
      <h1>Статистика</h1>
      <h2>Забезпечення кредитного модуля</h2>
      <div className="row">
        <div className="col-md-12">
          <a
            className="btn btn-primary btn-lg"
            role="button"
            href="https://statistic.ecampus.kpi.ua/zkm.html"
          >
            Перейти до модуля <i className="fa fa-external-link" />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default ZKM;
